import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"

import { Application } from "@hotwired/stimulus"
// import Chartjs from '@stimulus-components/chartjs'
// import CheckboxSelectAll from 'stimulus-checkbox-select-all'
// import Sortable from '@stimulus-components/sortable'

const application = Application.start()
// application.register('chart', Chartjs)
// application.register('checkbox-select-all', CheckboxSelectAll)
// application.register('sortable', Sortable)

application.warnings = true
application.debug = false
window.Stimulus = application

// import * as ActiveStorage from "@rails/activestorage"
import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle"
window.bootstrap = bootstrap

Rails.start()
// ActiveStorage.start()

// require("trix")
// require("@rails/actiontext")

import './sweetalert'
import "./controllers"
// import $ from 'jquery'