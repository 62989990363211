import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
    static targets = ['input_field', 'off_switch', 'on_switch']


    save_changes(event) {
        let data = new FormData()
        // user[host_video]

        alert("name " + this.input_fieldTarget.name);

        data.append(this.input_fieldTarget.name, this.value())

        alert(data);

        Rails.ajax({
            type: 'PATCH',
            url: this.url(),
            dataType: 'json',
            data: data,
            success: function (response) { console.log('Setting saved.') },
            error: function (response) { console.log('Setting could not be saved.')}
        })
    }

    switch_on(event) {
        let data = new FormData()
        // user[host_video]

        let that = this;

        data.append(this.off_switchTarget.name, '1')

        Rails.ajax({
            type: 'PATCH',
            url: this.url(this.off_switchTarget),
            dataType: 'json',
            data: data,
            success: function (response) {
                var on = document.getElementById(that.element.getAttribute('id') + '-on');
                var off = document.getElementById(that.element.getAttribute('id') + '-off');

                on.style.display = 'inline';
                off.style.display = 'none';
            },
            error: function (response) { console.log('Setting could not be saved.')}
        })
    }

    switch_off(event) {
        let data = new FormData()
        let that = this;

        data.append(this.on_switchTarget.name, '0')

        Rails.ajax({
            type: 'PATCH',
            url: this.url(this.on_switchTarget),
            dataType: 'json',
            data: data,
            success: function (response) {
                var on = document.getElementById(that.element.getAttribute('id') + '-on');
                var off = document.getElementById(that.element.getAttribute('id') + '-off');

                off.style.display = 'inline';
                on.style.display = 'none';
            },
            error: function (response) { console.log('Setting could not be saved.')}
        })
    }



    value() {
        alert("type " + this.input_fieldTarget.type);

        switch (this.input_fieldTarget.type) {
            case "checkbox":
                return this.input_fieldTarget.checked == true ? 1 : 0
            case "select-one":
            case "link":
                alert("value " + this.input_fieldTarget.value);
                return this.input_fieldTarget.value == '0' ? 1 : 0
        }
    }

    url(target) {
        return target.closest('form').getAttribute('action');
    }
}