// ONLY EDIT IN STIMULUS-BASE 1.1.0

import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'
import swal from "sweetalert2";

export default class extends Controller {
    static targets = ["self"]
    static values = { path: String, confirm: String }

    connect() {
        console.log('Connected: SuperTableItem')
        this._url = this.data.get('url')
    }

    delete(event) {
        let that = this

        console.log('Delete')

        const swalWithBootstrap = swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-info'
            },
            buttonsStyling: false
        })

        console.log('alert starting ' + that.confirmValue)

        swalWithBootstrap.fire({
            html: that.confirmValue,
            showCancelButton: true,
            confirmButtonText: 'Okay',
            cancelButtonText: 'Cancel'
        })
            .then((result) => {
                if (result.value) {
                    Rails.ajax({
                        type: 'DELETE',
                        url: that.pathValue,
                        dataType: 'json',
                        success: function (response) {
                            that.selfTarget.outerHTML = ''
                        },
                        error: function (response) { }
                    })

                }
            })
        console.log('alert ended')
    }
}